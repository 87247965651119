import {
    apiBaseUrl
} from "./api";
import * as tus from "tus-js-client";
import headerBuilder from "./headerBuilder";

async function createUpload(file, onProgress = function() {}, withUpload = function(upload) {
    upload.start();
}) {
    return new Promise((resolve, reject) => {
        const upload = new tus.Upload(file, {
            endpoint: `${apiBaseUrl}/tus/`,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            onError: (error) => reject(error),
            onProgress: (bytesUploaded, bytesTotal) => onProgress(upload, bytesUploaded, bytesTotal),
            onSuccess: () => resolve(upload),
            headers: headerBuilder.build(true),
        });
        withUpload(upload);
    });
}

export default {
    createUpload,
};